<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md8>
      <h1 class="display-3">Register</h1>
      <v-spacer />
      <br />
      <v-form ref="form" lazy-validation v-model="valid">
        <v-card>
          <v-card-title primary-title>
            <h3>Your Login Information</h3>
          </v-card-title>
          <v-card-text>
            <v-text-field
              :error-messages="createAccountFailureReasons.emailAddress"
              :disabled="isCreatingAccount"
              v-model.trim="emailAddress"
              label="Email Address"
              required
            ></v-text-field>
            <v-text-field
              :error-messages="createAccountFailureReasons.phoneNumber"
              :disabled="isCreatingAccount"
              v-model.trim="phoneNumber"
              return-masked-value
              v-mask="'(###) ###-####'"
              label="Phone Number"
              required
            ></v-text-field>
            <v-text-field
              :error-messages="createAccountFailureReasons.password"
              :disabled="isCreatingAccount"
              v-model="password"
              label="Password"
              :type="'password'"
              required
            ></v-text-field>
          </v-card-text>
        </v-card>
        <br />
        <v-card>
          <v-card-title primary-title>
            <h3>About You</h3>
          </v-card-title>
          <v-card-text>
            <v-layout align-space-between justify-space-around row fill-height>
              <v-flex md5>
                <v-text-field
                  v-model.trim="firstName"
                  label="First Name"
                  required
                  :error-messages="createAccountFailureReasons.firstName"
                  :disabled="isCreatingAccount"
                ></v-text-field>
                <v-text-field
                  v-model.trim="lastName"
                  label="Last Name"
                  required
                  :error-messages="createAccountFailureReasons.lastName"
                  :disabled="isCreatingAccount"
                ></v-text-field>
                <v-text-field
                  v-model.trim="departmentName"
                  label="Department Name (if applicable)"
                  :error-messages="createAccountFailureReasons.departmentName"
                  :disabled="isCreatingAccount"
                ></v-text-field>
              </v-flex>
              <v-flex md5>
                <v-text-field
                  v-model.trim="address1"
                  label="Address 1"
                  :error-messages="createAccountFailureReasons.address1"
                  :disabled="isCreatingAccount"
                ></v-text-field>
                <v-text-field v-model="address2" label="Address 2" :disabled="isCreatingAccount"></v-text-field>
                <v-text-field
                  v-model.trim="city"
                  label="City"
                  :error-messages="createAccountFailureReasons.city"
                  :disabled="isCreatingAccount"
                ></v-text-field>
                <v-text-field
                  v-model.trim="state"
                  label="State"
                  :error-messages="createAccountFailureReasons.state"
                  :disabled="isCreatingAccount"
                ></v-text-field>
                <v-text-field
                  v-model.trim="zipCode"
                  label="Zip Code"
                  :error-messages="createAccountFailureReasons.zipCode"
                  :disabled="isCreatingAccount"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
        <br />
        <UserAgreement v-on:update:agreement="agreementValid = $event" />
        <br />
        <v-card>
          <v-card-title primary-title>
            <h3>Subscription</h3>
          </v-card-title>
          <v-card-text>
            <p>A LiveCad subscription is $15 per year. {{promoText}}</p>

            <v-text-field
              label="First Responder Access Code (if applicable)"
              v-model="promoCode"
              :error-messages="promoCodeError"
              :disabled="disablePromoCode"
            >
              <template v-slot:append>
                <v-btn @click="applyCoupon" v-if="!disablePromoCode" small>Apply</v-btn>
              </template>
            </v-text-field>
            <br />
            <v-stripe-card
              v-if="creditCardRequired"
              v-model="source"
              :api-key="stripe"
              create="source"
            ></v-stripe-card>
            <br />
          </v-card-text>
        </v-card>
        <br />
        <div v-if="createAccountFailureReasons.length > 0">
          <v-alert
            v-for="reason in createAccountFailureReasons"
            :value="true"
            type="error"
            v-bind:key="reason.code"
          >{{reason.description}}</v-alert>
        </div>
        <v-btn
          class="success"
          @click="register"
          :disabled="isCreatingAccount || ccIsNotGood || !agreementValid"
        >
          <span v-if="isCreatingAccount">Creating...</span>
          <span v-if="!isCreatingAccount">Register</span>
        </v-btn>&nbsp;
        <v-btn @click="cancel" :disabled="isCreatingAccount">Cancel</v-btn>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UserAgreement from "./UserAgreement";
import axios from "axios";

export default {
  data() {
    return {
      valid: true,
      emailAddress: "",
      phoneNumber: "",
      password: "",
      firstName: "",
      lastName: "",
      departmentName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      source: null,
      promoCode: "",
      disablePromoCode: false,
      promoCodeError: "",
      promoText: "",
      creditCardRequired: true,
      agreementValid: false
    };
  },
  computed: {
    ...mapState({
      stripe: state => state.stripePublishableKey,
      createAccountFailureReasons: state => state.createAccountFailureReasons,
      isCreatingAccount: state => state.isCreatingAccount
    }),
    ccIsNotGood() {
      return this.source === null && this.creditCardRequired === true;
    }
  },
  methods: {
    ...mapActions(["createAccount", "resetCreateAccount"]),
    register() {
      this.createAccount({
        emailAddress: this.emailAddress,
        phoneNumber: this.phoneNumber,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        departmentName: this.departmentName,
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        state: this.state,
        zipCode: this.zipCode,
        source: this.source !== null ? this.source.id : null,
        promoCode: this.promoCode
      });
    },
    applyCoupon() {
      var url = "api/verifyCoupon/" + this.promoCode;

      axios.get(url).then(
        res => {
          this.disablePromoCode = true;

          let duration = "";
          if (res.data.duration === "forever") {
            duration = "forever";
          } else if (res.data.duration === "recurring") {
            duration = `for next ${res.data.duration_in_months} months`;
          } else {
            duration = "one-time";
          }

          if (res.data.percent_off) {
            this.promoText = `(Applied ${res.data.percent_off}% off ${duration} coupon - ${this.promoCode})`;
          } else if (res.data.amount_off) {
            this.promoText = `(Applied $${res.data.amount_off /
              100} off ${duration} coupon - ${this.promoCode})`;
          } else {
            this.promoText = `(Applied coupon ${this.promoCode})`;
          }

          if (res.data.percent_off === 100 || res.data.amount_off >= 1500) {
            this.creditCardRequired = false;
          }
        },
        () => {
          this.promoCodeError = "Invalid promo code.";
        }
      );
    },
    cancel() {
      this.$refs.form.reset();
      this.resetCreateAccount();
      this.$router.push({ name: "home" });
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  },
  components: { UserAgreement }
};
</script>